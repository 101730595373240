import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import toast, { Toaster } from 'react-hot-toast';
import config from '../config'

function OverviewCloseAlert(props) {
    const [status, setStatus] = React.useState(props.statusId.toString());
    const [assigned, setAssigned] = React.useState(props.assignedId.toString());
    const [comment, setComment] = React.useState(' ');
    const [selectedAgent, setSelectedAgent] = React.useState(0)
    

    const url = config.MAIN_API_MONIT_URL +  '/alerts/'

    const handleChange = function(event){
        setStatus(event.target.value)
    }

    const handleCommentChange = function(event){
        setComment(event.target.value)
    }

    const handleModalSave = function(){
        putAlert()
    }

    const putAlert = function(){
        axios.put(url + props.alertId, {
            comment: comment,
            as_id: status,
            ag_id: selectedAgent
        }, config.axiosConfig).then(() => {
            toast('Alert Closed');
            props.closeModal()
            props.reloadData();
        }).catch(() => {
            toast.error("Alert could not be closed... Error with Backend.")
        })
    }

    return (
        <div className="OverviewCloseAlert container">
            <div className="row">
            <i style={{marginBottom: "10px"}}>Please specify the correct status and give a comment.</i>

            {//<span>Blubsi: {status}</span>
                }

            <div className="col-12 form-group">
                <input   type="radio" value="0" checked={status === "0"}  id="status0" name="status" onChange={handleChange} /> <label for={"status0"}> issue open (unsolved) [0]</label> <br />
                <input   type="radio" disabled={true} value="1" checked={status === "1"}  id="status1" name="status" onChange={handleChange} /> <label for={"status1"}> issue solved (solved by server) [1]</label> <br />
                <input   type="radio" value="2" checked={status === "2"}  id="status2" name="status" onChange={handleChange} /> <label for={"status2"}> issue solved (solved by agent) [2]</label> <br />
        <input   type="radio" value="3"  checked={status === "3"} id="status3" name="status" onChange={handleChange} /> <label for={"status3"}> issue acknowledged (will not solve) [3]</label> <br />
        <input  type="radio" value="4" checked={status === "4"}  id="status4" name="status" onChange={handleChange} /> <label for={"status4"}> issue acknowledged (planned) [4]</label> <br />
        <input  type="radio" value="5" checked={status === "5"}  id="status5" name="status" onChange={handleChange} disabled={true} /> <label for={"status5"}> issue archived (confirmed by server) [5]</label> <br />
        </div>
        <p style={{marginTop: "20px"}}><textarea onChange={handleCommentChange} className="col-12 form-control"  placeholder={'Comment'}></textarea></p>
        <hr />
        Assign To:
        <select name="AssignTo" id="AssignTo"
                onChange={(e) => setSelectedAgent(e.target.value)}
            >
            <option value={0}>--- SELECT --- </option>
            {console.log(assigned)}
            {props.allAgents.map(item => (
                <option selected={assigned === item.ag_id.toString()} value={item.ag_id} key={item.ag_id}>{item.ag_skyfax}</option>
            ))}
        </select>
        </div>

            <div style={{float: "right"}}>
                <Button variant="secondary" onClick={props.closeModal}>
                    Cancel
                </Button>
                <Button variant="primary"  className="m-lg-2" onClick={handleModalSave}>
                    Update
                </Button>
            </div>

        </div>
    );
}

export default OverviewCloseAlert;
