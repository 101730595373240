import {createPortal} from "react-dom";
import {Button, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import config from "../config";
import {Markup} from "react-render-markup";
import Markdown from "markdown-to-jsx";

export function ValueInfoAlert({showValueInfoModal, reloadTypes, handleValueInfoModalClose, activeModalATDescription}){
    const [solution, setSolution] = useState("");
    const [desc, setDesc] = useState("");
    const [editing, setEditing] = useState(false)

    useEffect(() => {
        setDesc(activeModalATDescription.at_description)
        setSolution(activeModalATDescription.at_solution)
    }, [activeModalATDescription]);

    const handleCancelEditing = () => {
        setEditing(false)
        setDesc(activeModalATDescription.at_description)
        setSolution(activeModalATDescription.at_solution)
    }

    const handleEditing = () => {
        setEditing(true);
    }

    const handleEditSave = () => {
        axios.post(config.MAIN_API_MONIT_URL + "/alerts/types/update", {at_id: activeModalATDescription.at_id, at_description: desc, at_solution: solution}, config.axiosConfig)
            .then(res => {
                reloadTypes();
                setEditing(false);
            })
    }

    return createPortal(
        <Modal size="lg" show={showValueInfoModal} onHide={handleValueInfoModalClose}>
            <Modal.Body>
                <h4>{editing ? <input onChange={e => setDesc(e.target.value)} value={desc} className="form-control col-12 "/>
                    : desc}</h4>
                <p className="mt-3"><b>Solution: </b><br/>
                    {editing ?
                        <textarea onChange={(e) => setSolution(e.target.value)}
                                  value={solution} rows={5} className="col-12 mt-2 form-control"  placeholder={'Comment'}></textarea> :
                        solution ? <Markdown>{solution}</Markdown> : <></>}
                </p>
                <div className="float-end">
                    {!editing ? <button className="btn btn-success" onClick={handleEditing}><FontAwesomeIcon icon={faPencilAlt}/></button> :
                        <div>
                            <button className="btn btn-danger" onClick={handleCancelEditing}>Abbrechen</button>
                            <button className="btn btn-success m-lg-1" onClick={handleEditSave}>Speichern</button>
                        </div>}
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                <a className="" target={"_blank"} href={"https://www.markdownguide.org/cheat-sheet/"}>Markdown Hilfe</a>
                <Button  variant="secondary" onClick={handleValueInfoModalClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>,
    document.getElementById("show-alert"))
}