import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Modal, Tabs, Tab, Button, Badge, OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee, faInfoCircle, faPencilAlt, faSyncAlt } from "@fortawesome/free-solid-svg-icons";

import OverviewCloseAlert from './OverviewCloseAlert.js'
import config from '../config'
import dayjs from 'dayjs';
import {ValueInfoAlert} from "./ValueInfoAlert";
import Markdown from "markdown-to-jsx";

function Overview() {
    var relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)

    const [alertTypes, setAlertTypes] = useState([]);

    const [alerts, setAlerts] = useState([]);
    const [idFilter, setIdFilter] = useState('');
    const [levelFilter, setLevelFilter] = useState('');
    const [serverFilter, setServerFilter] = useState('');
    const [descriptionFilter, setDescriptionFilter] = useState('');
    const [valueFilter, setValueFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState(0);
    const [assignedFilter, setAssignedFilter] = useState(0);
    const [listOffset, setListOffset] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [activeModalAlertId, setActiveModalAlertId] = useState(-1);
    const [activeModalStatusId, setActiveModalStatusId] = useState(-1);
    const [activeModalAssignedId, setActiveModalAssignedId] = useState(0);
    const [activeModalServer, setActiveModalServer] = useState("")
    const [activeModalValue, setActiveModalValue] = useState("");
    const [allAgents, setAllAgents] = useState([])

    const [showValueInfoModal, setShowValueInfoModal] = useState(false);
    const [activeModalATDescription, setActiveModalATDescription] = useState(-1);

 

    const url = config.MAIN_API_MONIT_URL + '/alerts'

    // todo a global URL and then the routes

    const alert_types_url = config.MAIN_API_MONIT_URL + '/alerts/types'

    const agents_url = config.MAIN_API_MONIT_URL + '/agents'


    const reloadData = function(){
        axios.get(url, config.axiosConfig).then(data => {
            const parsedAlerts = parse_alerts(data.data)
            setAlerts(parsedAlerts)
            setListOffset(parsedAlerts.length)
        })
    }

    const setAgents = function (){
        axios.get(agents_url, config.axiosConfig).then(data => {
            setAllAgents(data.data)
        })
    }

    const formatBytes = function(bytes) {
        var units = ['B', 'KB', 'MB', 'GB', 'TB'], i;
     
        for (i = 0; bytes >= 1024 && i < 4; i++) {
            bytes /= 1024;
        }
     
        return bytes.toFixed(2) + units[i];
    }

    const parse_alerts = function(alerts){
        const parsedAlerts = alerts.map(alert =>  {
            if(alert.a_value.length > 0){
                alert.server_value = alert.a_value
            } else if(alert.at_id === 3){
                alert.server_value= formatBytes(alert.a_value * 1024 * 1024)+ ' ' + alert.at_name
            }  else {
                alert.server_value= alert.a_value+ ' ' + alert.at_name
            }
            
            return alert
        })
        return parsedAlerts
    }

    const reloadTypes = () => {
        axios.get(alert_types_url, config.axiosConfig).then(data => {
            setAlertTypes(data.data)
        }).catch(e => {
            if(e.response.status == 401){
                window.location.href =  config.MAIN_API_MONIT_URL + '/login'
            }
        })
    }
 

    useEffect(() => {
        reloadData()
        setAgents()
        reloadTypes();
    }, []); // run only once, which is important



    const loadMore = function(){
        axios.get(url + '?offset='+listOffset, config.axiosConfig).then(data => {
        const parsedAlerts = parse_alerts(data.data)
        const addedAlerts =  alerts.concat(parsedAlerts)
        console.log(addedAlerts)
        setAlerts(addedAlerts)
        setListOffset(addedAlerts.length)
   })
}

const openValueInfoModal = function(at_id){
    // todo need to load the info messages
   
    setShowValueInfoModal(true)
    setActiveModalATDescription(alertTypes.filter(types => types.at_id === at_id)[0])
}

const getAlertLevelClass = function(alert_level){
    const levels = {
        0: '#00FFFF', // info
        1: '#FFFF00', //warning
        2: '#FF9900', //error
        3: '#FF0000' // critical
    }
    const fontColor = {
        0: "black",
        1: "black",
        2: "black",
        3: "white"
    }

    return [levels[alert_level], fontColor[alert_level]]
}

const editAlert = function(id, stateId, server, value, assignedId){
    setActiveModalAlertId(id)
    setActiveModalStatusId(stateId)
    setActiveModalServer(server)
    setActiveModalValue(value)
    setActiveModalAssignedId(assignedId)
    setShowModal(true)
}

const handleModalClose = function(){
    setShowModal(false)
}

const handleValueInfoModalClose = function(){
    setShowValueInfoModal(false)
}

const getAlertStatusColor = (status) => {
    const states = {
        0: 'red',
        1: 'green',
        2: 'green',
        3: 'green',
        4: 'orange',
        5: 'green',
    }
    return states[status]
}

    // computed property
   const alertsFiltered = alerts.filter(alert => {
        
    let valueCheck = true
        if(valueFilter != null && valueFilter.length > 0){
            if(alert.a_info === ""){
                valueCheck = alert.server_value.toLowerCase().indexOf(valueFilter.toLowerCase()) >= 0
            }else {
                valueCheck = alert.a_info.toLowerCase().indexOf(valueFilter.toLowerCase()) >= 0
            }
       }

    let servCheck = true
        if(serverFilter != null && serverFilter.length > 0){
            servCheck = alert.s_name.toLowerCase().indexOf(serverFilter.toLowerCase()) >= 0
       }
       let idCheck = true
       if(idFilter != null && idFilter.length > 0){
        idCheck = (alert.a_id == idFilter)
      }

      let descCheck = true
      if(descriptionFilter != null && descriptionFilter.length > 0){
          descCheck = alert.comments.map(al => { return al.ac_comment + al.ac_agent }).join(" ").toLowerCase().indexOf(descriptionFilter.toLowerCase()) >= 0
     }

let statusCheck = true // do with real filter
if(statusFilter != null && statusFilter.length > 0){
    statusCheck = (alert.as_id == statusFilter)
  }


  let levelCheck = true // do with real filter
if(levelFilter != null && levelFilter.length > 0){
    levelCheck = (alert.al_id == levelFilter)
  }

       let assignedCheck = true // do with real filter
       if(assignedFilter != null && assignedFilter.length > 0){
           assignedCheck = (alert.ag_id == assignedFilter)
       }

     return servCheck && idCheck && descCheck  && statusCheck && valueCheck && levelCheck && assignedCheck
   })

    return (
        <div className="Overview">

        <Button onClick={() => window.location.reload()} style={{float: "right", marginTop: "-20px"}}><FontAwesomeIcon icon={faSyncAlt} /> Reload </Button>

            <ValueInfoAlert reloadTypes={reloadTypes} activeModalATDescription={activeModalATDescription} showValueInfoModal={showValueInfoModal}
                            handleValueInfoModalClose={handleValueInfoModalClose} />

<Modal size="lg" show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{activeModalServer} Alert {activeModalAlertId} <br/> <span style={{fontSize: "17px", fontWeight: "normal"}}>{activeModalValue.substr(0, 80)}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <OverviewCloseAlert reloadData={reloadData} allAgents={allAgents} alertId={activeModalAlertId} assignedId={activeModalAssignedId} statusId={activeModalStatusId} closeModal={handleModalClose}></OverviewCloseAlert>
        
        </Modal.Body>
      </Modal>
    

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th style={{maxWidth: "85px"}}>
                            <p>#</p>
                            <input style={{maxWidth: "70px"}}
                                type="text"
                                value={idFilter}
                                onChange={(e) =>
                                    setIdFilter(e.target.value)
                                }
                            />
                        </th>

                        <th  style={{maxWidth: "100px"}}>
                            <p>Level</p>
                            <select name="Status" style={{maxWidth: "85px"}} id="Status"  onChange={(e) =>
                                setLevelFilter(e.target.value)
                            }>
                                <option value={""}>--- SELECT ---</option>
                                <option value={3}>critical</option>
                                <option value={2}>error</option>
                                <option value={1}>warning</option>
                                <option value={0}>info</option>
                            </select>
                        </th>

                        <th>
                            <p>Server</p>
                            <input
                                type="text"
                                value={serverFilter}
                                onChange={(e) =>
                                    setServerFilter(e.target.value)
                                }
                            />
                        </th>

                        <th>
                            <p>Value</p>
                            <input
                                type="text"
                                value={valueFilter}
                                onChange={(e) =>
                                    setValueFilter(e.target.value)
                                }
                            />
                        </th>

                        <th>
                            <p>Comment</p>
                            <input
                                type="text"
                                value={descriptionFilter}
                                onChange={(e) =>
                                    setDescriptionFilter(e.target.value)
                                }
                            />
                        </th>

                        <th><p>Firstseen</p></th>
                        <th><p>Lastseen</p></th>
                        <th><p>Expires</p></th>
                        <th><p>Counter</p></th>
                        <th>
                            <p>Assign To</p>
                            <select name="AssignTo" id="AssignTo"  onChange={(e) =>
                                setAssignedFilter(e.target.value)
                            }>
                                <option value={""}>--- SELECT --- </option>
                                {allAgents.map(item => (
                                    <option value={item.ag_id} key={item.ag_id}>{item.ag_skyfax}</option>
                                ))}
                            </select>
                        </th>
                        <th>
                            <p>Status</p>
                            <select name="Status" id="Status"  onChange={(e) =>
                                setStatusFilter(e.target.value)
                            }>
                                <option value={""}>--- SELECT --- </option>
                                <option value={0}>unresolved</option>
                                <option value={1}>solved by server</option>
                                <option value={2}>solved by agent</option>
                                <option value={3}>will not solve</option>
                                <option value={4}>planned</option>
                                <option value={5}>solved (archived)</option>
                            </select>
                        </th>
                        <th><p>Actions</p></th>
                    </tr>
                </thead>
                <tbody>


                    {alertsFiltered.map((alert, index) =>
                        <tr key={index}>
                            <td>{alert.a_id}</td>
                            <td><span style={{borderRadius: "10%", backgroundColor: getAlertLevelClass(alert.al_id)[0], color: getAlertLevelClass(alert.al_id)[1], padding: "2px 8px 2px 8px", fontSize: "0.8em", fontWeight: "bold"}}>{alert.al_name}</span></td>
                            <td><b>{alert.s_name}</b></td>
                            <td style={{cursor: 'pointer'}} >
                                <FontAwesomeIcon icon={faInfoCircle} onClick={() => openValueInfoModal(alert.at_id)} /> &nbsp;
                                {alert.a_info ? <Markdown>{alert.a_info}</Markdown> : alert.server_value}
                            </td>
                            <td>
                                <ul>{alert.comments.map((comment, index) => {
                                    return <li key={index}><small><i>{comment.ac_agent}:</i></small> {comment.ac_comment} <span style={{fontSize: '9px'}}><i>at</i>  {comment.ac_timestamp}</span></li>
                                })}
                                </ul>
                            </td>
                            <td style={{fontSize: "0.75em", fontWeight: "bold"}}><a title={alert.a_firststseen}>{dayjs(alert.a_firststseen).fromNow()}</a></td>
                            <td style={{fontSize: "0.8em"}}><a title={alert.a_lastseen}>{dayjs(alert.a_lastseen).fromNow()}</a></td>
                            <td style={{fontSize: "0.8em"}}><a title={alert.a_expires}>{dayjs(alert.a_expires).fromNow()}</a></td>
                            <td>{alert.a_counter}</td>
                            <td>{allAgents.filter( (agent => agent.ag_id == alert.ag_id)).map(agentFiltered =>(
                                <b>{agentFiltered.ag_skyfax}</b>
                            ))}</td>
                            <td style={{color: getAlertStatusColor(alert.as_id)}} >{alert.as_short}</td>
                            <td>
                                <Button onClick={() => editAlert(alert.a_id, alert.as_id, alert.s_name, alert.a_info ? alert.a_info : alert.server_value, alert.ag_id)}><FontAwesomeIcon icon={faPencilAlt} /></Button>
                            </td>
                        </tr>

                    )}

                </tbody>
            </Table>

            <h3>--</h3>
            <div>
            <Button onClick={loadMore}>Mehr</Button>
            </div>


        </div>
    );
}

export default Overview;
