import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import config from '../config'

function DashboardSimple() {
const [servers, setServers] = useState([]);

    const url = config.MAIN_API_MONIT_URL +  '/servers/overview'


    useEffect(() => {
        axios.get(url, config).then(data => {
            setServers(data.data)
        })
    }, []); // run only once, which is important

  
    return (
        <div className="DashboardSimple">

            <div className="container">
            <div className="row">
            <div className="col-3">Server 1</div>
            <div className="col-3 bg-success"></div>
            <div className="col-3 bg-primary"></div>
            </div>
            </div>

        </div>
    );
}

export default DashboardSimple;
