import Cookies from 'js-cookie';

const config = {
    MAIN_API_MONIT_URL: 'https://api.monit.elaion.ch/api/v1', //'http://localhost/elaion.ch/monit.elaion.ch/api.monit.elaion.ch/public/api/v1',
    axiosConfig: {
        headers: {
            "Content-type": "application/json",
             "Authorization": `Bearer ${Cookies.get("auth_token")}`,
        },
   }
}

export default config;
