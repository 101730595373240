import './App.css';
import Overview from './components/Overview.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Toaster } from 'react-hot-toast';
import jwt_decode from 'jwt-decode';
import { useCookies } from 'react-cookie';
import { BrowserRouter as Router, Routes, Route, Redirect } from 'react-router-dom';
import DashboardSimple from './components/DashboardSimple';
import config from './config';
import Logo from './monit_api.png';

function App() {
    // todo routing
    // todo if error redirect to login
    const [cookies, setCookie] = useCookies(['user']);
    const loadUsername = () => {
        try {
            const token = cookies['auth_token']
            const payload = jwt_decode(token)
            return payload.user.firstname + ' ' + payload.user.lastname
        } catch {
            window.location.href =  config.MAIN_API_MONIT_URL + '/login'
        }
    }
   const username = loadUsername()

    return (
        <div className='App'>
            <div style={{float: "left", cursor: "pointer"}} onClick={() => window.location.reload()}><img src={Logo} alt="Logo" /><b style={{fontSize: "20px"}}>ELAION-MONIT</b></div>
            <div>Hello {username}</div>
            <Router>
                <Routes>
                    <Route path='/' element={<Overview />} />
                    <Route path='/dashboard' element={<DashboardSimple />} />
                </Routes>
            </Router>
            <Toaster />
        </div>
    );
}

export default App;
